import type { Theme } from "@wind/ui/theme";

export const themes: Array<{
  name: string;
  theme: Theme;
}> = [
  {
    name: "Windmill Web",

    theme: {
      colors: {
        accent: "#17181c",
        background: {
          base: "#fbfbfc",
          surface: "#ffffff",
          surfaceMuted: "#f5f5f5",
        },
        text: {
          primary: "#17181c",
          secondary: "#49494b",
          tertiary: "#929295",
          inverse: "#fffffe",
        },
        border: {
          default: "#ebebeb",
        },
      },
      radius: 0.2,
      borderWidth: 1,
    },
  },
  {
    name: "Blue Sky",
    theme: {
      colors: {
        accent: "#3da9fc",
        background: {
          base: "#fffffe",
          surface: "#e3f6f5",
        },
        text: {
          primary: "#094067",
          secondary: "#5f6c7b",
          inverse: "#fffffe",
          accentInverse: "#042238",
        },
        border: {
          default: "#90b4ce",
        },
      },
      radius: 0.2,
      borderWidth: 1,
    },
  },
  {
    name: "Dark Sky",
    theme: {
      colors: {
        accent: "#57c780",
        background: {
          base: "#050605",
          surface: "#1b1d1c",
        },
        text: {
          primary: "#eefcf3",
          inverse: "#191a23",
        },
        border: {
          default: "#3f4240",
        },
      },
      radius: 0.2,
      borderWidth: 1,
    },
  },
];

export const defaultTheme = themes[0].theme;
